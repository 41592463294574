<template>
  <div>
    <!-- 五行婚姻 -->
    <el-form :inline="true" :model="searchInfo" class="demo-form-inline">
      <el-form-item style="margin-left: 30px" label="五行选择">
        <el-select v-model="searchInfo.theFiveElementsId" clearable placeholder="请选择五行">
          <el-option key="1" label="金" value="1">
          </el-option>
          <el-option key="2" label="木" value="2">
          </el-option>
          <el-option key="3" label="水" value="3">
          </el-option>
          <el-option key="4" label="火" value="4">
          </el-option>
          <el-option key="5" label="土" value="5">
          </el-option>
        </el-select>
      </el-form-item>

      <!-- <el-form-item style="margin-left: 30px" label="颜色名称（关键字）">
        <el-input v-model="searchInfo.color"></el-input>
      </el-form-item> -->
      <el-form-item class="right-btns">
        <el-button type="primary" @click="getList">查询</el-button>
        <el-button type="success" @click="addColorHandleSet">新增五行婚姻</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column type="selection" width="55">
      </el-table-column>

      <el-table-column label="五行" align="center" min-width="80">
        <template slot-scope="scope">
          <span v-if="scope.row.fiveElementsId === 1">金</span>
          <span v-if="scope.row.fiveElementsId === 2">木</span>
          <span v-if="scope.row.fiveElementsId === 3">水</span>
          <span v-if="scope.row.fiveElementsId === 4">火</span>
          <span v-if="scope.row.fiveElementsId === 5">土</span>
        </template>
      </el-table-column>
      <el-table-column label="内容" prop="content">
      </el-table-column>
      <el-table-column label="创建时间" prop="createDate" width="190">
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="deleteColor(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-bottom: 20px">
      <el-col :span="24">
        <div style="padding-top: 20px;">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[5, 10, 50, 100]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </el-col>
    </el-row>
    <el-dialog title="修改项目信息" :visible.sync="dialogFormVisible" width="600px" top="200px">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item style="margin-left: 30px" label="五行选择" :label-width="formLabelWidth02" prop="theFiveElementsId">
          <el-select v-model="form.theFiveElementsId" clearable placeholder="请选择五行">
            <el-option key="金" label="金" value="1">
            </el-option>
            <el-option key="木" label="木" value="2">
            </el-option>
            <el-option key="水" label="水" value="3">
            </el-option>
            <el-option key="火" label="火" value="4">
            </el-option>
            <el-option key="土" label="土" value="5">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="内容" :label-width="formLabelWidth" prop="color">
          <el-input v-model="form.content" auto-complete="off" required="required"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="form.id === 0 ? addColor('form') : handleSet('form')">确 定</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">

      </div>
    </el-dialog>
  </div>
</template>
<script>
import { upMarriage, adminSelectMarriage,addMarriage,delMarriage } from '../../api/fiveElementColors/index';
export default {
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pagesize: 10,
      total: 0,
      searchInfo: {
        theFiveElementsId: null,
      },
      form: {
        theFiveElementsId: "",
        content: "",
        state: "",
        id: null,
      },
      formLabelWidth: '120px',
      formLabelWidth02: '90px',
      dialogFormVisible: false,
      rules: {
        theFiveElementsId: [{ required: true, message: '五行', trigger: 'blur' }],
        color: [{ required: true, message: '颜色', trigger: 'blur' }],
        state: [{ required: true, message: '状态', trigger: 'blur' }]
      }
    }
  },
  methods: {
    getList() {
      let that = this;
      // let param = new URLSearchParams();
      // param.append('pageSize', this.pagesize);
      // param.append('pageNumber', this.currentPage);
      const pageParameter = {
        "pageIndex": this.currentPage,
        "pageSize": this.pagesize,
        "parameter": {
          "fiveElementsId": this.searchInfo.theFiveElementsId
        }
      }
      adminSelectMarriage(pageParameter).then(res => {
        console.log(res);
        let len = res.data.body.list.length;
        that.total = res.data.body.count;
        that.tableData = [];
        for (let i = 0; i < len; i++) {
          that.tableData.push(res.data.body.list[i]);
        }
      })

    },
    handleSizeChange: function (size) {
      this.pagesize = size;
      this.getList();
      console.log(this.pagesize)  //每页下拉显示数据
    },
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
      console.log(this.currentPage)  //点击第几页
      this.getList();
    },
    handleEdit(row) {
      console.log(row)
      //对弹出层中的表单赋值
      this.form.theFiveElementsId = row.fiveElementsId
      this.form.content = row.content
      this.form.state = row.state
      this.form.id = row.id
      //开启弹出层
      this.dialogFormVisible = true
    },
    handleSet(form) {
      this.$refs[form].validate((valid) => {
        if (!valid) {
          console.log(this.form)
        }
      });
      var data = {
        id: this.form.theFiveElementsId,
        content: this.form.content
      }
      console.log("修改",data);
      console.log("修改1",this.form);
      let data1 = {
        id:this.form.id,
        content:this.form.content
      }
      upMarriage(data1).then(res => {
        if (res.data.code === 1001) {
          this.msgsuccess('修改成功');
          this.dialogFormVisible = false
          this.getList()
          return
        }
        this.msgerror(res.data.msg);
      })
    },
    addColorHandleSet() {
      this.form.theFiveElementsId = null
      this.form.color = null
      this.form.state = null
      this.form.id = 0
      //开启弹出层
      this.dialogFormVisible = true
    },
    addColor(form) {
      this.$refs[form].validate((valid) => {
        if (!valid) {
          console.log(this.form)
        }
      });
      var data = {
        fiveElementsId: this.form.theFiveElementsId,
        content: this.form.content,
      }
      console.log(data);
      addMarriage(data).then(res => {
        if (res.data.code === 1001) {
          this.msgsuccess('添加成功');
          this.dialogFormVisible = false
          this.getList()
          return
        }
        this.msgerror(res.data.msg);
      })
    },
    deleteColor(fFiveElementColors) {
      this.$confirm("是否确认删除此记录", "提示", {
        iconClass: "el-icon-question",//自定义图标样式
        confirmButtonText: "确认",//确认按钮文字更换
        cancelButtonText: "取消",//取消按钮文字更换
        showClose: true,//是否显示右上角关闭按钮
        type: "warning",//提示类型  success/info/warning/error
      }).then(() => {
        //确认操作
        delMarriage(fFiveElementColors).then(res => {
          if (res.data.code === 1001) {
            this.msgsuccess('删除成功');
            this.getList();
          } else {
            this.msgerror(res.data.msg);
          }
        })
      }).then((data) => {
        console.log(data);
        //取消操作
      }).catch((err) => {
        //捕获异常
        console.log(err);
      });
    }
  },
  mounted() {
    this.getList();
    adminSelectMarriage({
      "pageIndex": 1, //页码
      "pageSize": 10, //每页显示条数
      "parameter": {
        "fiveElementsId": 0 //五行id 1金 2木 3水 4火 5土
      }
    }).then(res => {
      console.log("获取五行婚姻", res);
    })
  }
}

</script>